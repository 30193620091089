<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="1200"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
        <v-col>
          <v-btn tile :color="variables.colorSuccess" dark class="mb-2 modal-cadastro-btn-cadastrar" @click="openModal"> Cadastrar notificação Individual </v-btn>
        </v-col>
        <!-- <v-col  cols="2">
          <v-select
            v-model="tipoBusca"
            :items="listaTipoBusca"
            menu-props="auto"
            hide-details
            label="Tipo busca"
            single-line
          ></v-select>
        </v-col> -->
      <!-- <v-col cols="2"> -->
          <!-- <v-file-input v-model="file" class="file mt-5 ml-5" placeholder="Arquivo"></v-file-input> -->
          <!-- <v-file-input v-model="file" :readonly="false" class="file mt-5 ml-5"  label="Arquivo"></v-file-input> -->
      <!-- </v-col> -->
    </template>

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>


      <index />

    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { required, } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import * as convertCvs from 'papaparse'
import { map, size } from 'lodash'
// import { validate } from 'gerador-validador-cpf'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
export default {
  name: 'ModalSmsMensagem',
  data: () => ({
    activeModal: false,
    form: {},
    file: null,
    msgAcentuada: false,
    tipoBusca: 6,
    tipoNotificacao: null,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ],
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    firstSearch: true,
    debounce: null
  }),
  components: {
    index: () => import('../listNotificacaoIndividual')
  },
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('notificacao', ['itensSelectEntities', 'item']),
    variables: () => variables,
    errorsMessage () {
      const errors = []
      if (!this.$v.form.message.$dirty) return errors
      !this.$v.form.message.required && errors.push('Digite uma mensagem')
      if (size(this.numeros) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    },

    listaTipoNotificacao () {
      return [
        {text: 'WHATSAPP', value: 'WHATSAPP' },
        {text: 'PORTAL-IND', value: 'PORTAL-IND' },
        {text: 'PORTAL', value: 'PORTAL' }
      ]
    }
  },
  mounted () {
    Events.$on('Daodos::modal::sms', () => { this.activeModal = true })
    // this.filtroEntities()
  },
  watch: {
    'item.tipoBusca' (v) {
      this.item.entityIds = []
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    file (val) {
      this.convertCvs(val)
    }
  },
  methods: {
    ...mapActions('notificacao', ['sendSms', 'addFiltroAtual', 'addDadosTable', 'cadastrarItem', 'getItens']),
    ...mapActions('notificacao', { getItensEntities: 'obterItens' }),
    convertCvs (val) {
      // var lines= val.split("\n");
      // eslint-disable-next-line
      const vm = this
      let arquivo = convertCvs.parse(val, {
        config: {
          header: true,
          encoding: "utf-8"
        },
        complete: (results) => {
          // eslint-disable-next-line
          vm.clear()
          setTimeout(() => {
            let dados = vm.formatCvsFile(results)
            vm.addDadosTable(dados)
          }, 500)
        }
      })
      console.log(arquivo)
    },
    openModal () { this.activeModal = true },

    filtroEntities () {
      const vm = this
      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter: { "entityTypeId_In":`${this.item.tipoBusca}`}
      }).then(() => {
        vm.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatCvsFile ({data}) {
      if (size(data) < 1) return false
      let array = []
      // chaves no header do arquivo
      let keys  = data[0]
      // eslint-disable-next-line
      let dados = map(data, (v, k) => {
        if (k > 0) { // pula a a posição 0 que é o as chaves do arquivo
          let obj = {}
          v.forEach((value, chave) =>{
            obj[keys[chave]] = value
          })
          array.push(obj)   
        }
      })
      return array
    },
    clear() {
      Events.$emit('sms:clearTable')
    },
    submit () {
      // if (this.$v.form.message.$invalid) {
      //   this.$v.form.$touch()
      //   return false
      // }

      let dados = {
        jsonData: this.item.message,
        channelId: this.item.tipoNotificacao,
        entityId: this.item.entityIds.length > 0 ? this.item.entityIds : undefined
      }
      this.cadastrarItem([dados]).then(() => {
        successSnackbar('Mensagem cadastrada com sucesso!')
        this.closeModal()
        this.getItens()
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatDados () {
      let { form } = this
      let dados = this.numeros
      let text = String(form.message).split(' ')
      let regex = /[áàâãéèêíïóôõöúçñ/]/g
      let existeAcento = false
      
      text.forEach(nome => {
        let valido = nome.split(/ +/).every(parte => regex.test(parte))
        if (valido) { existeAcento = true }
      })

      if (size(dados)=== 0) return false
      // formata dados que vem da tabela ou por arquivo cvs, nunca dos dois
      dados = map(dados, v => ({
        number: 55 + String(v.numero || (`${v.ddd}${v.cellphone}`)).replace(/\s/g, ''),
        msg: form.message, code: existeAcento ? 8 : 0
      }))

      return dados
    },
    closeModal () {
      this.getItens()
      this.activeModal = false
    },

    searchAutoComplete() {
      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.search('')
          return
        }

        vm.search(vm.searchText)
      }, 600)
    },

    search (val) {
      this.isLoadingAutoComplete = true
      // this.obterItensSelect({ _filter: { "Entities:jsonData.name_ilike":`%${val}%` }, pageSize: this.$store.getters.ItensPaginacao })
      //   .catch(err => {
      //     errorSnackbar(err.error)
      //   })
      //   .finally(() => this.isLoadingAutoComplete = false)

      let typeFilter = null

      if (!isNaN(val.replace(/\D/g, '')) && this.item.tipoBusca === 6) {
        typeFilter = { "Entities:jsonData.cpf_ilike":`%${val.replace(/\D/g, '')}%` }
      } else if (!isNaN(val.replace(/\D/g, ''))) {
        typeFilter = { "Entities:jsonData.cnpj_ilike":`%${val.replace(/\D/g, '')}%` }
      } else {
        typeFilter = { "Entities:jsonData.name_ilike":`%${val}%` }
      }

      const _filter = { "entityTypeId_In":`${this.item.tipoBusca}`, ...typeFilter }

      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter
      }).then(() => {
        this.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
      .finally(() => this.isLoadingAutoComplete = false)
    },

    isIndividual () {
      if (this.item.entityIds.length > 0) {
        this.item.tipoNotificacao = 'PORTAL-IND'
        return true
      }
      return false
    }
  },
  validations () {
    return {
      form: {
        message: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/components/modal-cadastro';
</style>
